'use client'
import Link from 'next/link'
import { redirect, useRouter, useSearchParams } from 'next/navigation'
import { AlertCircle } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { use, useEffect, useState, useRef } from 'react'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { zodResolver } from '@hookform/resolvers/zod'
import Logo from '@/components/Logo'
import ROUTES from '../routes'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { supabaseClient } from '@/lib/supabase'
import { useAuth } from '@/contexts/AuthContext'
import ForgotPasswordDialog from '@/components/dialogs/ForgotPasswordDialog'
import { passwordSchema } from '@/lib/schemas'
import Footer from '@/components/Footer'

export const runtime = 'edge'

export default function Login() {
  const router = useRouter()
  const { me } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordResetDialogOpen, setIsPasswordResetDialogOpen] =
    useState(false)
  const [error, setError] = useState<null | string>(null)
  const searchParams = useSearchParams()
  const redirect = searchParams.get('redirect')
  const loginButtonRef = useRef<HTMLButtonElement | null>(null); // Specify the type of loginButtonRef

  const formSchema = z.object({
    email: z.string().email(),
    password: passwordSchema,
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const signIn = async ({ email, password }: z.infer<typeof formSchema>) => {
    setIsLoading(true)
    setError(null)

    const { error } = await supabaseClient.auth.signInWithPassword({
      email,
      password,
    })

    if (error) {
      setError('Incorrect email or password.')
      setIsLoading(false)
      return
    }
  }

  // Once we've successfully signed in, redirect to original destination or the dashboard
  const followRedirect = () => {
    const successRedirect = redirect ? redirect : ROUTES.DASHBOARD.href

    console.log('successRedirect', successRedirect)

    return router.replace(successRedirect)
  }

  useEffect(() => {
    if (me) {
      setIsLoading(false)
      followRedirect()
    }
  }, [me])

  return (
    <>
      <div className="flex-1 h-full flex flex-col w-full p-16 sm:max-w-md gap-2 mx-auto">
        <Form {...form}>
          <form
            className="animate-in flex-1 flex flex-col w-full justify-center gap-4 text-foreground"
            onSubmit={form.handleSubmit(signIn)}
          >
            <Link href="/" className="mb-8 self-center">
              <Logo />
            </Link>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <Input
                    type="password"
                    placeholder="Password"
                    {...field}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        if (loginButtonRef.current) {
                          loginButtonRef.current.click();
                        }
                      }
                    }}
                  />
                )}
              />

              <Button
                variant="link"
                className="p-0 text-xs contents text-neutral-800 mt-1"
                onClick={() => setIsPasswordResetDialogOpen(true)}
              >
                Forgot Password?
              </Button>
            </div>

            <Button type="submit" className="mt-2" isLoading={isLoading} ref={loginButtonRef}>
              Login
            </Button>
            <p className="text-center text-sm mt-2">
              <span className="text-secondary-foreground/50">
                Don't have an account?
              </span>{' '}
              <Link href={ROUTES.SIGNUP.href}>Sign Up</Link>
            </p>

            {error && (
              <Alert variant="destructive" className="mt-2">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          </form>
        </Form>
        <ForgotPasswordDialog
          open={isPasswordResetDialogOpen}
          onOpenChange={setIsPasswordResetDialogOpen}
        />
      </div>
      <Footer />
    </>
  )
}
